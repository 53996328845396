import { lazy } from 'react';
import { ComponentContainer } from '@/components/componentContainer';

const Error = lazy(async () => await import('@/pages/Generic/Error'));
const Forbidden = lazy(async () => await import('@/pages/Generic/Forbidden'));
const NotAuthorized = lazy(
  async () => await import('@/pages/Generic/NotAuthorized'),
);
const NotAuthorizedUser = lazy(
  async () => await import('@/pages/Generic/NotAuthorizedUser'),
);

const PermissionRequested = lazy(
  async () => await import('@/pages/Generic/PermissionRequested'),
);

export default [
  {
    path: 'generic/error',
    element: (
      <ComponentContainer>
        <Error />
      </ComponentContainer>
    ),
  },
  {
    path: 'generic/forbidden',
    element: (
      <ComponentContainer>
        <Forbidden />
      </ComponentContainer>
    ),
  },
  {
    path: '/generic/unauthorized-app',
    element: (
      <ComponentContainer>
        <NotAuthorized />
      </ComponentContainer>
    ),
  },
  {
    path: '/generic/unauthorized-user',
    element: (
      <ComponentContainer>
        <NotAuthorizedUser />
      </ComponentContainer>
    ),
  },
  {
    path: '/generic/permission-requested',
    element: (
      <ComponentContainer>
        <PermissionRequested />
      </ComponentContainer>
    ),
  },
];
