import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  CAPTCHA_CHALLENGE_HTTP_HEADER,
  CAPTCHA_HTTP_HEADER,
} from '@nosinovacao/nosid-mfe-common';
import {
  AAMicroserviceConfiguration,
  ResponseAction,
  ValidateUsername,
} from '@/models';

import { useAppContext } from '@/context';
import { useNavigation } from '@/navigation';
import { useNosidSignInMutation } from './mutation.hook';

export function useValidateUsernameBase(
  captchaFlow: string,
  endpoint: keyof AAMicroserviceConfiguration,
  loader: boolean,
  onResponse?: (data: ResponseAction) => void,
) {
  const { config, navigationStateService } = useAppContext();
  const { navigateTo, navigateToError, updateCurrentDataState } =
    useNavigation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const res = useNosidSignInMutation(
    async (
      axios,
      input: { username: string; honeypot: string; tokenChallenge?: string },
    ) => {
      const body: ValidateUsername = {
        Username: input.username,
        Email: input.honeypot ? input.honeypot : undefined,
      };
      const headers: Record<string, string | undefined> = {
        Authorization: `Bearer ${
          navigationStateService.getCurrentState()?.Token
        }`,
      };

      if (input.tokenChallenge) {
        headers[CAPTCHA_HTTP_HEADER] = input.tokenChallenge;
        headers[CAPTCHA_CHALLENGE_HTTP_HEADER] = 'true';
      } else {
        headers[CAPTCHA_HTTP_HEADER] = await executeRecaptcha?.(captchaFlow);
      }
      return await axios
        .post<ResponseAction>(config.APIConfiguration.AA[endpoint], body, {
          headers,
        })
        .then((response) => {
          updateCurrentDataState({
            Username: input.username,
          });
          return response.data;
        });
    },
    {
      loader,
      onResponse: (data) => {
        onResponse?.(data);
        if (data.Error) {
          navigateToError(data);
        } else {
          navigateTo(data);
        }
      },
    },
  );

  return res;
}
