import {
  AnalyticsOption,
  CoreUtilsService,
  ValidatorEmail,
} from '@nosinovacao/nosid-mfe-common';

export class UtilsService extends CoreUtilsService {
  protected LoginOptionPhoneKey: string = AnalyticsOption.Phone;

  getAliasType(alias: string) {
    const isEmail = ValidatorEmail(alias) === null;

    if (isEmail) {
      return AnalyticsOption.Email;
    }
    if (!Number.isNaN(+alias)) {
      return AnalyticsOption.Phone;
    }
    return AnalyticsOption.Username;
  }

  trimValues(body: any) {
    const newValue = Array.isArray(body) ? [...body] : { ...body };
    Object.entries(newValue).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        return;
      }
      switch (typeof value) {
        case 'string':
          newValue[key] = value.trim();
          break;
        case 'object':
          newValue[key] = this.trimValues(newValue[key]);
          break;
        default:
          break;
      }
    });
    return newValue;
  }
}
