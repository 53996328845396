export const CLIENT_ID_STORAGE_KEY = 'NOSID-SignIn-ClientId';
export const LOGIN_HINT_STORAGE_KEY = 'NOSID-SignIn-LoginHint';
export const DEVICE_ID_STORAGE_KEY = 'NOSID-SignIn-DeviceId';
export const DEVICE_FLOW_STORAGE_KEY = 'NOSID-SignIn-DeviceFlow';
export const ENTERPRISE_CONTEXT_STORAGE_KEY = 'NOSID-SignIn-EnterpriseContext';
export const SESSION_TOKEN_STORAGE_KEY = 'NOSID-SignIn-SessionToken';
export const KEEP_SESSION_STORAGE_KEY = 'NOSID-SignIn-KeepSession';
export const NAVIGATION_HISTORY_STORAGE_KEY = 'NOSID-SignIn-NavigationHistory';
export const AUTHORIZE_STORAGE_KEY = 'NOSID-SignIn-Authorize';
export const TWOFA_EMAIL_INPUT_KEY = '2fa_em';
