import { FC } from 'react';
import { Button, useI18n } from '@nosinovacao/nosid-mfe-common';
import { ReturnButtonSkin } from '../backButton.props';

export const BackButtonWoo: FC<ReturnButtonSkin> = ({
  display,
  className,
  goBack,
}) => {
  const { t } = useI18n();
  if (!display) return <></>;
  return (
    <Button
      className={className ?? ''}
      format="secondary"
      type="button"
      onClick={goBack}>
      {t('Common.Translation.Back')}
    </Button>
  );
};
