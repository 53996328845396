import { lazy } from 'react';
import { Action } from '@/models';
import { ComponentContainer } from '@/components/componentContainer';

const Terms = lazy(async () => await import('@/pages/TermsConsents/terms'));

const Consents = lazy(
  async () => await import('@/pages/TermsConsents/consents'),
);

const PrivacyPolicies = lazy(
  async () => await import('@/pages/TermsConsents/privacy-policies'),
);

export default [
  {
    path: 'terms-consents/consents',
    element: (
      <ComponentContainer>
        <Consents actions={[Action.Consents]} />
      </ComponentContainer>
    ),
  },
  {
    path: 'terms-consents/terms',
    element: (
      <ComponentContainer>
        <Terms actions={[Action.TermsAndConditions]} />
      </ComponentContainer>
    ),
  },
  {
    path: 'terms-consents/privacy-policies',
    element: (
      <ComponentContainer>
        <PrivacyPolicies actions={[Action.PrivacyPolicies]} />
      </ComponentContainer>
    ),
  },
];
