import { lazy } from 'react';
import { Action } from '@/models';
import { ComponentContainer } from '@/components/componentContainer';

const LandingPage = lazy(
  async () => await import('@/pages/AccountProtection/LandingPage'),
);

const CompletePage = lazy(
  async () => await import('@/pages/AccountProtection/Complete'),
);
const CodeInputPage = lazy(
  async () => await import('@/pages/AccountProtection/CodeInput'),
);

const RollbackPage = lazy(
  async () => await import('@/pages/AccountProtection/Rollback'),
);

const RollbackCompletePage = lazy(
  async () => await import('@/pages/AccountProtection/RollBackComplete'),
);

export default [
  {
    path: 'account-protection/landing-page',
    element: (
      <ComponentContainer>
        <LandingPage validActions={[Action.Protection]} />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-protection/rollback',
    element: (
      <ComponentContainer>
        <RollbackPage />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-protection/complete',
    element: (
      <ComponentContainer>
        <CompletePage validActions={[Action.Protection]} />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-protection/email-code',
    element: (
      <ComponentContainer>
        <CodeInputPage
          type="Email"
          validActions={[Action.ProtectionEmailCodeValidation]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-protection/phone-code',
    element: (
      <ComponentContainer>
        <CodeInputPage
          type="Phone"
          validActions={[Action.ProtectionPhoneCodeValidation]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-protection/rollback',
    element: (
      <ComponentContainer>
        <RollbackPage />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-protection/rollback-complete',
    element: (
      <ComponentContainer>
        <RollbackCompletePage />
      </ComponentContainer>
    ),
  },
];
