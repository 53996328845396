import {
  Button,
  IconLoader,
  htmlClass,
  useI18n,
} from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { LogoutProps } from '../logoutButton.props';

export const LogoutButtonDefault: FC<LogoutProps> = ({
  children,
  execute,
  className,
  isLoading,
  disabled,
  lastItem,
}) => {
  const { t } = useI18n();
  const logoutButton = (
    <Button
      id="ButtonEndSession"
      className={htmlClass('w-full', className ?? '')}
      format="secondary"
      type="submit"
      disabled={disabled ?? isLoading}
      onClick={execute}>
      {isLoading ? (
        <IconLoader height={22} width={22} />
      ) : (
        t('Common.EndSession.Title')
      )}
    </Button>
  );
  return lastItem ? (
    <>
      {children}
      {logoutButton}
    </>
  ) : (
    <>
      {logoutButton}
      {children}
    </>
  );
};
