import { FC } from 'react';
import { NosIconNavigationArrow } from '@nosinovacao/nosid-mfe-common';
import { ReturnButtonSkin } from '../backButton.props';

export const BackButtonWtf: FC<ReturnButtonSkin> = ({ display, goBack }) => {
  if (!display) return <></>;
  return (
    <div
      onClick={goBack}
      id="BackButton"
      className="flex cursor-pointer justify-center items-center gap-2">
      <NosIconNavigationArrow
        orientation="left"
        width={20}
        height={20}
        className="!fill-wtfNeutralsBlack"
      />
    </div>
  );
};
