import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { FooterSkinProps } from '../footer.props';
import { LogoutButton } from '@/components/logoutButton';

export const FooterWtf: FC<FooterSkinProps> = ({
  className,
  children,
  showLogoutButton,
  overflow,
}) => {
  const footerClass = htmlClass(
    'flex flex-col gap-6 mt-auto  w-full sticky',
    className ?? '',
    overflow
      ? `bottom-0 p-4  sticky  border-solid border-3 border-black bg-white`
      : '',
  );

  return (
    <div className={footerClass}>
      <div
        className={htmlClass(
          'flex flex-row  gap-6 px-5 w-full flex-wrap',
          className ?? '',
          !overflow ? 'mb-10' : '',
        )}>
        {showLogoutButton ? (
          <LogoutButton showLogoutButton={showLogoutButton}>
            {children}
          </LogoutButton>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
