import { FC } from 'react';
import {
  Button,
  IconLoader,
  NosIconFeedbackWarning,
  NosIconSadFaceWtf,
  NosTypography,
  useI18n,
} from '@nosinovacao/nosid-mfe-common';
import { ErrorSkinProps } from '../error.props';
import { Container } from '@/components/container';
import { Footer } from '@/components/footer';

export const ErrorWtf: FC<ErrorSkinProps> = ({
  action,
  button,
  level,
  title,
  tooltip,
  onClick,
  loadingIndex,
  setLoadingIndex,
}) => {
  const { t } = useI18n();
  const getIcon = (width: number, height: number) => {
    switch (level) {
      case 'warning':
        return <NosIconFeedbackWarning width={width} height={height} />;
      case 'error':
        return <NosIconSadFaceWtf width={width} height={height} />;
      default:
        <></>;
    }
  };
  return (
    <Container
      containerClassName="bg-wtfBackgroundGradientError"
      className="flex flex-col justify-start items-start">
      <div className="mt-14 w-full flex flex-col gap-6 ">
        <div className="flex w-full flex-col justify-center items-center text-center">
          {getIcon(98, 98)}
        </div>
        <div className="flex w-full flex-col justify-center gap-4 items-center">
          <NosTypography variant="headlineL" className="Error">
            {t(title)}
          </NosTypography>
          <NosTypography variant="longText-Medium" className="Error">
            {t(tooltip)}
          </NosTypography>
        </div>
      </div>
      {action && button && (
        <Footer
          showLogoutButton={{
            lastItem: false,
            execute: () => {
              setLoadingIndex(1);
            },
            isLoading: loadingIndex === 1,
            disabled: loadingIndex >= 0,
          }}
          className=" tablet-sm:mt-40 w-full   !gap-6">
          <Button
            className="w-full"
            disabled={loadingIndex >= 0}
            onClick={onClick}>
            {loadingIndex === 2 ? (
              <IconLoader height={22} width={22} />
            ) : (
              t(button)
            )}
          </Button>
        </Footer>
      )}
    </Container>
  );
};
