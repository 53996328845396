import { FC } from 'react';
import { ComponentContainerProps } from './componentContainer.props';
import { ErrorBoundary } from '../errorBoundary';
import { Suspense } from '../suspense';

export const ComponentContainer: FC<ComponentContainerProps> = ({
  children,
  displaySuspenseLoading = true,
}) => {
  return (
    <ErrorBoundary>
      <Suspense displaySuspenseLoading={displaySuspenseLoading}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};
