import { FC } from 'react';
import { useAppContext } from '@/context';
import { SubContainerDefault } from './default/subcontainer.default';
import { SubContainerProps } from './subcontainer.props';
import { SubContainerSporting } from './sporting/subcontainer.sporting';
import { SubContainerBenfica } from './benfica/subcontainer.benfica';
import { SubContainerWoo } from './woo/subcontainer.woo';
import { SubContainerWtf } from './wtf/subcontainer.wtf';

export const SubContainer: FC<SubContainerProps> = ({ children, ...props }) => {
  const { templates, canUseDarkTheme } = useAppContext();
  switch (true) {
    case templates.isWtfTemplate:
      return (
        <SubContainerWtf
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </SubContainerWtf>
      );
    case templates.isBenficaTemplate:
      return (
        <SubContainerBenfica
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </SubContainerBenfica>
      );
    case templates.isSportingTemplate:
      return (
        <SubContainerSporting
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </SubContainerSporting>
      );
    case templates.isWooTemplate:
      return (
        <SubContainerWoo
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </SubContainerWoo>
      );
    default:
      return (
        <SubContainerDefault
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </SubContainerDefault>
      );
  }
};
