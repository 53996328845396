import { lazy } from 'react';
import { ComponentContainer } from '@/components/componentContainer';

const SelectProfile = lazy(async () => await import('@/pages/Profiles/Select'));
const New = lazy(async () => await import('@/pages/Profiles/New'));

export default [
  {
    path: 'profiles',
    element: (
      <ComponentContainer>
        <SelectProfile />
      </ComponentContainer>
    ),
  },
  {
    path: 'profiles/new',
    element: (
      <ComponentContainer>
        <New />
      </ComponentContainer>
    ),
  },
];
