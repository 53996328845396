import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { ContainerSkinProps } from '../container.props';

export const ContainerWtf: FC<ContainerSkinProps> = ({
  children,
  className,
  containerClassName,
  el,
  style,
  onSubmit,
}) => {
  const klass = htmlClass(
    'max-desktop-sm:overflow-x-hidden desktop-sm:w-svw relative overflow-y-scroll min-h-[100dvh] flex flex-col flex-nowrap justify-start items-start  z-10 h-svh',
    className ?? '',
  );
  const containerClass = htmlClass(
    'relative flex justify-center bg-wtfBackgroundGradient',
    containerClassName ?? '',
  );

  switch (el) {
    case 'form':
      return (
        <div style={{ ...style }} className={containerClass}>
          <form
            className={klass}
            onSubmit={(ev) => {
              onSubmit?.(ev as unknown as Event);
            }}>
            {children}
          </form>
        </div>
      );

    case 'div':
    default:
      return (
        <div style={{ ...style }} className={containerClass}>
          <div className={klass}>{children}</div>
        </div>
      );
  }
};
