import { lazy } from 'react';
import { Action } from '@/models';
import { ComponentContainer } from '@/components/componentContainer';

const Username = lazy(
  async () => await import('@/pages/Authentication/Username'),
);
const Password = lazy(
  async () => await import('@/pages/Authentication/Password'),
);
const SingleStep = lazy(
  async () => await import('@/pages/Authentication/SingleStep'),
);

const Blocked = lazy(
  async () => await import('@/pages/Authentication/Blocked'),
);

export default [
  {
    path: 'authentication/username',
    element: (
      <ComponentContainer>
        <Username validActions={[Action.Unauthenticated]} />
      </ComponentContainer>
    ),
  },
  {
    path: 'authentication/password',
    element: (
      <ComponentContainer>
        <Password
          validActions={[Action.PasswordInput, Action.PasswordInputLoginHint]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'authentication/login',
    element: (
      <ComponentContainer>
        <SingleStep validActions={[Action.UnauthenticatedSinglePageLogin]} />
      </ComponentContainer>
    ),
  },
  {
    path: 'authentication/user-blocked',
    element: (
      <ComponentContainer>
        <Blocked />
      </ComponentContainer>
    ),
  },
];
