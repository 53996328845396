import { lazy } from 'react';
import { ComponentContainer } from '@/components/componentContainer';

const Authorize = lazy(async () => await import('@/pages/Entry/Authorize'));
const Logout = lazy(async () => await import('@/pages/Entry/Logout'));

export default [
  {
    path: 'authorize',
    element: (
      <ComponentContainer displaySuspenseLoading={false}>
        <Authorize />
      </ComponentContainer>
    ),
  },
  {
    path: 'authorize/index',
    element: (
      <ComponentContainer displaySuspenseLoading={false}>
        <Authorize />
      </ComponentContainer>
    ),
  },
  {
    path: 'logout',
    element: (
      <ComponentContainer displaySuspenseLoading={false}>
        <Logout />
      </ComponentContainer>
    ),
  },
];
