import { IStorage } from '@nosinovacao/nosid-mfe-common';
import { ENTERPRISE_CONTEXT_STORAGE_KEY } from '@/constants';

export class EnterpriseContextService {
  constructor(private readonly sessionStorage: IStorage) {}

  get enterpriseContext() {
    return this.sessionStorage.getString(ENTERPRISE_CONTEXT_STORAGE_KEY) ?? '';
  }

  set enterpriseContext(context: string) {
    this.sessionStorage.setString(ENTERPRISE_CONTEXT_STORAGE_KEY, context);
  }
}
