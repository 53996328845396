/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import {
  Button,
  IconLoader,
  NosTypography,
  WooIconAlert,
  htmlClass,
  useI18n,
  useInMediaQuery,
} from '@nosinovacao/nosid-mfe-common';
import { ErrorSkinProps } from '../error.props';
import { Container } from '@/components/container';
import { Footer } from '@/components/footer';
import { SubContainer } from '@/components/subcontainer';
import { Header } from '@/components/header';

export const ErrorWoo: FC<ErrorSkinProps> = ({
  action,
  button,
  level,
  title,
  tooltip,
  onClick,
  shouldNotDisplayButton,
  loadingIndex,
  setLoadingIndex,
}) => {
  const { t } = useI18n();
  const isDesktop = useInMediaQuery('desktop-sm');

  return (
    <Container>
      <Header
        displayLogo={isDesktop}
        className={htmlClass(
          // eslint-disable-next-line no-nested-ternary
          !isDesktop ? '!bg-wooYellowCyber' : '',
          'min-h-[373px] tablet-sm:min-h-max tablet-sm:pt-[279px]',
        )}>
        {!isDesktop && <IconComponent level={level} />}
      </Header>
      <SubContainer>
        <div className="flex flex-col">
          {isDesktop && <IconComponent level={level} />}
          <div className={htmlClass('flex flex-col gap-2')}>
            <NosTypography variant="longText-Regular">
              {t(tooltip)}
            </NosTypography>
            {shouldNotDisplayButton && (
              <NosTypography variant="longText-Regular">
                {t(title)}
              </NosTypography>
            )}
          </div>
          {action && button && !shouldNotDisplayButton && (
            <Footer
              showLogoutButton={{
                lastItem: true,
                execute: () => {
                  setLoadingIndex(1);
                },
                isLoading: loadingIndex === 1,
                disabled: loadingIndex >= 0,
              }}
              footerClassName="desktop-sm:mt-10">
              <Button
                className="w-full"
                disabled={loadingIndex >= 0}
                onClick={onClick}>
                {loadingIndex === 2 ? (
                  <IconLoader height={22} width={22} />
                ) : (
                  t('Generic.Error.Woo.Button')
                )}
              </Button>
            </Footer>
          )}
        </div>
      </SubContainer>
    </Container>
  );
};

const IconComponent: FC<{ level: 'error' | 'warning' }> = ({ level }) => {
  const { t } = useI18n();
  const isDesktop = useInMediaQuery('desktop-sm');

  const getIcon = () => {
    switch (level) {
      default:
        return (
          <WooIconAlert
            className="fill-black  desktop-sm:fill-wooFucsia"
            width={isDesktop ? 70 : 107}
            height={isDesktop ? 70 : 107}
          />
        );
    }
  };

  return (
    <div className="flex flex-col justify-start items-start gap-2 desktop-sm:gap-10">
      {getIcon()}
      <div className="flex flex-col justify-start items-start gap-4">
        <NosTypography variant={isDesktop ? 'headlineL' : 'sectionTitle'}>
          {t('Generic.Error.Woo.Title')}
        </NosTypography>
      </div>
    </div>
  );
};
