import { IStorage, NosIdAppContextType } from '@nosinovacao/nosid-mfe-common';
import { LOGIN_HINT_STORAGE_KEY, SESSION_TOKEN_STORAGE_KEY } from '@/constants';

export class LoginHintContextService {
  constructor(
    private readonly sessionStorage: IStorage,
    private readonly nosIdContext: NosIdAppContextType,
  ) {}

  public authorizeLoginHintContext() {
    if (this.nosIdContext.templates.isWooTemplate) {
      return '';
    }
    return this.sessionStorage.getString(LOGIN_HINT_STORAGE_KEY) ?? '';
  }

  get loginHintContext() {
    return this.sessionStorage.getString(LOGIN_HINT_STORAGE_KEY) ?? '';
  }

  set loginHintContext(loginHintQueryParam: string) {
    if (this.loginHintContext !== loginHintQueryParam) {
      this.nosIdContext.localStorageService.remove(SESSION_TOKEN_STORAGE_KEY);
      this.nosIdContext.sessionStorageService.remove(SESSION_TOKEN_STORAGE_KEY);
    }
    this.sessionStorage.setString(LOGIN_HINT_STORAGE_KEY, loginHintQueryParam);
  }
}
