import { FC } from 'react';
import { ReturnButton } from './backButton.props';
import { useAppContext } from '@/context';
import { BackButtonDefault } from './default/backButton.default';
import { BackButtonWtf } from './wtf/backButton.wtf';
import { BackButtonSporting } from './sporting/backButton.sporting';
import { BackButtonWoo } from './woo/backButton.woo';

export const BackButton: FC<ReturnButton> = ({ ...props }) => {
  const { templates } = useAppContext();
  switch (true) {
    case templates.isWtfTemplate:
      return <BackButtonWtf {...props} />;
    case templates.isSportingTemplate:
      return <BackButtonSporting {...props} />;
    case templates.isWooTemplate:
      return <BackButtonWoo {...props} />;
    default:
      return <BackButtonDefault {...props} />;
  }
};
