import { Redirect } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { MainLayout } from '@/components/layout';
import AccountAssociationRoutes from '@/pages/AccountAssociation';
import AuthenticationRoutes from '@/pages/Authentication';
import EntryRoutes from '@/pages/Entry';
import GenericRoutes from '@/pages/Generic';
import MandatoryEmailRoutes from '@/pages/MandatoryEmail';
import PasswordRecoveryRoutes from '@/pages/PasswordRecovery';
import AccountSelectionRoutes from '@/pages/AccountSelection';
import SocialNetworkRoutes from '@/pages/SocialNetworks';
import DeviceCodeRoutes from '@/pages/DeviceCode';
import TermsConsentsRoutes from '@/pages/TermsConsents';
import ProfilesRoutes from '@/pages/Profiles';
import TwoFactorAuthenticationRoutes from '@/pages/TwoFactorAuthentication';
import SignupRoutes from '@/pages/Signup';
import AccountProtectionRoutes from '@/pages/AccountProtection';

export const Router: FC = () => {
  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: '/',
          element: <MainLayout />,
          children: [
            ...EntryRoutes,
            ...AuthenticationRoutes,
            ...GenericRoutes,
            ...TwoFactorAuthenticationRoutes,
            ...AccountAssociationRoutes,
            ...AccountSelectionRoutes,
            ...PasswordRecoveryRoutes,
            ...MandatoryEmailRoutes,
            ...SocialNetworkRoutes,
            ...DeviceCodeRoutes,
            ...TermsConsentsRoutes,
            ...ProfilesRoutes,
            ...SignupRoutes,
            ...AccountProtectionRoutes,
            {
              path: '*',
              element: <Redirect to="/authorize" />,
            },
          ],
        },
      ])}
    />
  );
};
