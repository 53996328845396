import { lazy } from 'react';
import { ComponentContainer } from '@/components/componentContainer';

const CodeInput = lazy(
  async () => await import('@/pages/TwoFactorAuthentication/CodeInput'),
);

const Promote = lazy(
  async () => await import('@/pages/TwoFactorAuthentication/Promote'),
);

export default [
  {
    path: 'two-factor-authentication/code-input',
    element: (
      <ComponentContainer>
        <CodeInput type="Phone" />
      </ComponentContainer>
    ),
  },
  {
    path: 'two-factor-authentication/code-input-email',
    element: (
      <ComponentContainer>
        <CodeInput type="Email" />
      </ComponentContainer>
    ),
  },
  {
    path: 'two-factor-authentication/promote',
    element: (
      <ComponentContainer>
        <Promote />
      </ComponentContainer>
    ),
  },
];
