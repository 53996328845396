import { lazy } from 'react';
import { Action } from '@/models';
import { ComponentContainer } from '@/components/componentContainer';

const LandingPage = lazy(
  async () => await import('@/pages/SocialNetworks/LandingPage'),
);

const SingleStep = lazy(
  async () => await import('@/pages/Authentication/SingleStep'),
);

const Complete = lazy(
  async () => await import('@/pages/SocialNetworks/Complete'),
);

export default [
  {
    path: 'social/associate-nosid',
    element: (
      <ComponentContainer>
        <LandingPage />
      </ComponentContainer>
    ),
  },
  {
    path: '/social/authentication/login',
    element: (
      <ComponentContainer>
        <SingleStep
          validActions={[Action.SocialNetworkAssociationSingleStep]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'social/complete',
    element: (
      <ComponentContainer>
        <Complete />
      </ComponentContainer>
    ),
  },
];
