import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { FooterSkinProps } from '../footer.props';
import { LogoutButton } from '@/components/logoutButton';

export const FooterWoo: FC<FooterSkinProps> = ({
  className,
  children,
  overflow,
  footerClassName,
  showLogoutButton,
}) => {
  const footerClass = htmlClass(
    'w-full pb-10 mt-6',
    'tablet-sm:sticky',
    overflow
      ? 'bottom-0 pt-6 bg-gradient-to-t from-white/95  to-white/0  backdrop-blur-[2px] sticky'
      : 'flex flex-row gap-6  ',
    footerClassName ?? '',
  );

  return (
    <div className={footerClass}>
      <div
        className={htmlClass(
          'flex flex-col justify-center tablet-sm:flex-row gap-6 w-full tablet-sm:mx-8 desktop-sm:mx-0 desktop-sm:justify-start',
          className ?? '',
        )}>
        {showLogoutButton ? (
          <LogoutButton showLogoutButton={showLogoutButton}>
            {children}
          </LogoutButton>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
