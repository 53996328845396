import {
  NosIdAppContext,
  NosIdAppContextType,
  NosidBaseStateProvider,
} from '@nosinovacao/nosid-mfe-common';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { AppContext, AppContextType } from '@/context';
import { Router } from '@/routes';

const queryClient = new QueryClient();

export const App: FC<{
  context: AppContextType;
  nosidContext: NosIdAppContextType;
}> = ({ context, nosidContext }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={nosidContext.config.RecaptchaSiteKey}>
      <QueryClientProvider client={queryClient}>
        <NosidBaseStateProvider>
          <NosIdAppContext.Provider value={nosidContext}>
            <AppContext.Provider value={context}>
              <Router />
              <canvas
                id="device-id"
                style={{
                  width: '100vw',
                  height: '100vh',
                  display: 'none',
                }}
              />
            </AppContext.Provider>
          </NosIdAppContext.Provider>
        </NosidBaseStateProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
};
