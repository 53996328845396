import { lazy } from 'react';
import { ComponentContainer } from '@/components/componentContainer';

const SelectAccount = lazy(
  async () => await import('@/pages/AccountSelection/SelectAccount'),
);

export default [
  {
    path: 'account-selection/select',
    element: (
      <ComponentContainer>
        <SelectAccount />
      </ComponentContainer>
    ),
  },
];
