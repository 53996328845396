import { FC } from 'react';
import { ContainerDefault } from './default/container.default';
import { ContainerProps } from './container.props';
import { ContainerWtf } from './wtf/container.wtf';
import { useAppContext } from '@/context';
import { ContainerBenfica } from './benfica/container.benfica';
import { ContainerSporting } from './sporting/container.sporting';
import { ContainerWoo } from './woo/container.woo';

export const Container: FC<ContainerProps> = ({ children, ...props }) => {
  const { templates, canUseDarkTheme } = useAppContext();
  switch (true) {
    case templates.isWtfTemplate:
      return (
        <ContainerWtf
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </ContainerWtf>
      );
    case templates.isBenficaTemplate:
      return (
        <ContainerBenfica
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </ContainerBenfica>
      );
    case templates.isSportingTemplate:
      return (
        <ContainerSporting
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </ContainerSporting>
      );
    case templates.isWooTemplate:
      return (
        <ContainerWoo
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </ContainerWoo>
      );
    default:
      return (
        <ContainerDefault
          variant={templates.clientTemplate.App}
          isDarkMode={canUseDarkTheme}
          {...props}>
          {children}
        </ContainerDefault>
      );
  }
};
