import { FC } from 'react';
import { ErrorBoundary as ErrorBoundaryReact } from 'react-error-boundary';
import Error from '@/pages/Generic/Error';
import { ErrorBoundaryProps } from './errorBoundary.props';

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => {
  return (
    <ErrorBoundaryReact fallback={<Error />}>{children}</ErrorBoundaryReact>
  );
};
