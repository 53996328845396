import {
  ErrorCodes,
  ModuleName,
  Screen,
  TemplatesService,
  useSetNavigationData,
} from '@nosinovacao/nosid-mfe-common';
import { FC, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useAppContext } from '@/context';
import { ResponseAction } from '@/models';
import { useNavigation } from '@/navigation';
import { ErrorDefault } from './default/error.default';
import { ErrorSkinProps } from './error.props';
import { ErrorWtf } from './wtf/error.wtf';
import { ErrorWoo } from './woo/error.woo';
import { useLogout } from '@/api';

export function getSkin(template: TemplatesService, props: ErrorSkinProps) {
  switch (true) {
    case template.isWtfTemplate:
      return <ErrorWtf {...props} />;
    case template.isWooTemplate:
      return <ErrorWoo {...props} />;
    default:
      return <ErrorDefault {...props} />;
  }
}

const Error: FC = () => {
  const { templates, logger } = useAppContext();
  useSetNavigationData(ModuleName.Generic, Screen.ScreenError, logger);
  const { data } = useNavigation();

  const errorBoundary = useErrorBoundary();

  const { logout } = useLogout();

  const [loadingIndex, setLoadingIndex] = useState(-1);

  const getButtonClick = useMemo(
    () => () => {
      setLoadingIndex(2);
      errorBoundary?.resetBoundary();
      logout('/authorize');
    },
    [logout, errorBoundary, setLoadingIndex],
  );

  const getLeveFromResponseAction = (d: ResponseAction | null) => {
    switch (d?.Error?.Code) {
      case ErrorCodes.AccessDenied:
        return 'error';
      default:
        return 'warning';
    }
  };

  const props = useMemo<ErrorSkinProps>(() => {
    return {
      loadingIndex,
      setLoadingIndex,
      action: data?.Action,
      title: data?.Error
        ? `Errors.${data?.Error?.Code ?? ''}.Id`
        : `Errors.DefaultError.Id`,
      tooltip: data?.Error
        ? `Errors.${data?.Error?.Code ?? ''}.Message`
        : `Errors.DefaultError.Message`,
      button: data?.Error
        ? `Errors.${data?.Error?.Code ?? ''}.Button`
        : `Errors.DefaultError.Button`,
      onClick: getButtonClick,
      shouldNotDisplayButton: !!shouldNotDisplayButton(data, templates),
      level: getLeveFromResponseAction(data),
    };
  }, [data, getButtonClick, loadingIndex, templates]);

  return getSkin(templates, props);
};

function shouldNotDisplayButton(
  data: ResponseAction<unknown> | null,
  templates: TemplatesService,
) {
  return (
    data?.Error?.Code === ErrorCodes.UserNotFound && templates.isWooTemplate
  );
}

export default Error;
