import { lazy } from 'react';
import { Action } from '@/models';
import { ComponentContainer } from '@/components/componentContainer';

const DirectSignup = lazy(
  async () => await import('@/pages/Signup/DirectSignup'),
);

const LandingPage = lazy(
  async () => await import('@/pages/Signup/LandingPage'),
);

const SendSms = lazy(async () => await import('@/pages/Signup/SendSms'));
const ChangePhone = lazy(
  async () => await import('@/pages/Signup/ChangePhone'),
);
const ValidateCode = lazy(
  async () => await import('@/pages/Signup/ValidateCode'),
);

const Complete = lazy(async () => await import('@/pages/Signup/Complete'));
const EmailVerify = lazy(
  async () => await import('@/pages/Signup/EmailVerify'),
);

const EmailVerified = lazy(
  async () => await import('@/pages/Signup/EmailVerified'),
);

const DigitalBirthInit = lazy(
  async () => await import('@/pages/Signup/DigitalBirthInit'),
);
const DigitalBirthLandingPage = lazy(
  async () => await import('@/pages/Signup/DigitalBirthLandingPage'),
);

const NewPasswordPage = lazy(
  async () => await import('@/pages/Signup/NewPassword'),
);

export default [
  {
    path: 'signup',
    element: (
      <ComponentContainer>
        <DirectSignup />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/index',
    element: (
      <ComponentContainer>
        <DirectSignup />
      </ComponentContainer>
    ),
  },
  {
    path: 'o/:id/Signup',
    element: (
      <ComponentContainer>
        <DirectSignup />
      </ComponentContainer>
    ),
  },
  {
    path: 'o/:id/Signup/index',
    element: (
      <ComponentContainer>
        <DirectSignup />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/landing-page',
    element: (
      <ComponentContainer>
        <LandingPage validActions={[Action.SignUp]} flow="signup" />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/send-sms',
    element: (
      <ComponentContainer>
        <SendSms />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/change-phone',
    element: (
      <ComponentContainer>
        <ChangePhone />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/validate-code',
    element: (
      <ComponentContainer>
        <ValidateCode />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/complete',
    element: (
      <ComponentContainer>
        <Complete />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/email-verify',
    element: (
      <ComponentContainer>
        <EmailVerify />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/email-verified',
    element: (
      <ComponentContainer>
        <EmailVerified />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/digital-birth/init',
    element: (
      <ComponentContainer>
        <DigitalBirthInit />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/digital-birth/landing-page',
    element: (
      <ComponentContainer>
        <DigitalBirthLandingPage />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/digital-birth/complete',
    element: (
      <ComponentContainer>
        <Complete flow="digitalBirth" />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/social/landing-page',
    element: (
      <ComponentContainer>
        <LandingPage
          validActions={[Action.SignUpSocialNetwork]}
          flow="social"
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/social/complete',
    element: (
      <ComponentContainer>
        <Complete flow="social" />
      </ComponentContainer>
    ),
  },
  {
    path: 'signup/new-password',
    element: (
      <ComponentContainer>
        <NewPasswordPage />
      </ComponentContainer>
    ),
  },
];
