import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { FooterSkinProps } from '../footer.props';
import { LogoutButton } from '@/components/logoutButton';

export const FooterDefault: FC<FooterSkinProps> = ({
  className,
  children,
  overflow,
  showLogoutButton,
  isDarkMode,
}) => {
  const footerClass = htmlClass(
    'mt-auto w-full pb-10',
    'tablet-sm:sticky',
    'desktop-sm:mt-auto desktop-sm:pb-6',
    overflow
      ? `bottom-0 pt-6 bg-gradient-to-t ${
          isDarkMode ? 'from-black/15  to-black/0' : 'from-white/95  to-white/0'
        }  backdrop-blur-[2px] sticky  desktop-sm:-bottom-1 desktop-sm:absolute`
      : 'tablet-sm:mt-14  desktop-sm:mt-auto  flex flex-row gap-6  ',
  );

  return (
    <div className={footerClass}>
      <div
        className={htmlClass(
          'flex max-tablet-sm:mt-10 flex-col tablet-sm:flex-row gap-6 px-5 w-full tablet-sm:px-0   tablet-sm:mx-auto tablet-sm:max-w-[516px] desktop-sm:px-[108px]  desktop-sm:max-w-[636px]',
          className ?? '',
        )}>
        {showLogoutButton ? (
          <LogoutButton showLogoutButton={showLogoutButton}>
            {children}
          </LogoutButton>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
