import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { SubContainerSkinProps } from '../subcontainer.props';

export const SubContainerWoo: FC<SubContainerSkinProps> = ({
  el,
  children,
  className,
  mainClassName,
  ...props
}) => {
  const kclass = htmlClass(
    `flex flex-col px-8  w-full`,
    `tablet-sm:px-[78px] `,
    'desktop-sm:px-[195px] desktop-sm:w-[70%]',
    mainClassName ?? '',
  );

  return (
    <div className={kclass}>
      <div className={htmlClass(className ?? '', 'desktop-sm:max-w-[602px]')}>
        {children}
      </div>
    </div>
  );
};
