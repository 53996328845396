import { lazy } from 'react';
import { ComponentContainer } from '@/components/componentContainer';

const LandingPage = lazy(
  async () => await import('@/pages/DeviceCode/LandingPage'),
);

const SelectUser = lazy(
  async () => await import('@/pages/DeviceCode/SelectUser'),
);
const Complete = lazy(async () => await import('@/pages/DeviceCode/Complete'));

export default [
  {
    path: 'activate',
    element: (
      <ComponentContainer>
        <LandingPage />
      </ComponentContainer>
    ),
  },
  {
    path: 'activate/select-user',
    element: (
      <ComponentContainer>
        <SelectUser />
      </ComponentContainer>
    ),
  },
  {
    path: 'activate/complete',
    element: (
      <ComponentContainer>
        <Complete />
      </ComponentContainer>
    ),
  },
];
