import { lazy } from 'react';
import { ComponentContainer } from '@/components/componentContainer';

const LandingPage = lazy(
  async () => await import('@/pages/MandatoryEmail/LandingPage'),
);

const ChangeEmail = lazy(
  async () => await import('@/pages/MandatoryEmail/ChangeEmail'),
);
const Complete = lazy(
  async () => await import('@/pages/MandatoryEmail/Complete'),
);

export default [
  {
    path: 'mandatory-email/landing-page',
    element: (
      <ComponentContainer>
        <LandingPage />
      </ComponentContainer>
    ),
  },
  {
    path: 'mandatory-email/change-email',
    element: (
      <ComponentContainer>
        <ChangeEmail />
      </ComponentContainer>
    ),
  },
  {
    path: 'mandatory-email/complete',
    element: (
      <ComponentContainer>
        <Complete />
      </ComponentContainer>
    ),
  },
];
