import { IStorage } from '@nosinovacao/nosid-mfe-common';
import { DEVICE_ID_STORAGE_KEY } from '@/constants';

export class DeviceIdService {
  private readonly id: Promise<string | undefined>;

  constructor(private readonly storage: IStorage) {
    this.id = this.getFingerPrintId()
      .then((id) => id ?? this.getFallbackId())
      .catch(() => this.getFallbackId());
  }

  get deviceId() {
    return this.id;
  }

  private getFallbackId() {
    return this.getFromStorage() ?? this.createIdAndSave();
  }

  private getFromStorage() {
    return this.storage.getString(DEVICE_ID_STORAGE_KEY);
  }

  private createIdAndSave() {
    if (!window.crypto?.getRandomValues) {
      return undefined;
    }
    const id: string = [...window.crypto.getRandomValues(new Uint32Array(4))]
      .map((v) => v.toString(16))
      .join('-');
    this.storage.setString(DEVICE_ID_STORAGE_KEY, id);
    return id;
  }

  private async getFingerPrintId() {
    if (!window.crypto?.subtle?.digest) {
      return undefined;
    }
    const canvas = document.getElementById('device-id')! as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return undefined;
    }

    ctx.fillStyle = 'rgb(255,0,255)';
    ctx.beginPath();
    ctx.rect(20, 20, 150, 100);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.beginPath();
    ctx.fillStyle = 'rgb(0,255,255)';
    ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();

    const txt = 'abz190#$%^@£éú';
    ctx.textBaseline = 'top';
    ctx.font = '17px "Arial 17"';
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = 'rgb(255,5,5)';
    ctx.rotate(0.03);
    ctx.fillText(txt, 4, 17);
    ctx.fillStyle = 'rgb(155,255,5)';
    ctx.shadowBlur = 8;
    ctx.shadowColor = 'red';
    ctx.fillRect(20, 12, 100, 5);

    // hashing function
    const src = canvas.toDataURL();
    let hash = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < src.length; i++) {
      const char = src.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + char;
      // eslint-disable-next-line no-bitwise
      hash &= hash;
    }
    return hash.toString();
  }
}
