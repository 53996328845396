import { FC, Suspense as SuspenseReact } from 'react';
import { IconLoader } from '@nosinovacao/nosid-mfe-common';
import { SuspenseProps } from './suspense.props';

export const Suspense: FC<SuspenseProps> = ({
  children,
  displaySuspenseLoading,
}) => {
  return (
    <SuspenseReact
      fallback={
        displaySuspenseLoading ? <IconLoader mainLoader forceVisible /> : null
      }>
      {children}
    </SuspenseReact>
  );
};
