import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { SubContainerSkinProps } from '../subcontainer.props';

export const SubContainerWtf: FC<SubContainerSkinProps> = ({
  el,
  children,
  className,
  mainClassName,
  ...props
}) => {
  const kclass = htmlClass(`flex flex-col h-fit  w-full`, mainClassName ?? '');

  return (
    <div className={kclass}>
      <div
        className={htmlClass(
          className ?? 'flex flex-col',
          el === 'form'
            ? 'border-3 border-solid border-black bg-white p-4 mx-5'
            : 'px-4',
        )}>
        {children}
      </div>
    </div>
  );
};
