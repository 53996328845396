import { lazy } from 'react';
import { Action, AssociationType } from '@/models';
import { ComponentContainer } from '@/components/componentContainer';

const LandingPage = lazy(
  async () => await import('@/pages/AccountAssociation/LandingPage'),
);

const HolderInput = lazy(
  async () => await import('@/pages/AccountAssociation/HolderInput'),
);

const ContactSelection = lazy(
  async () => await import('@/pages/AccountAssociation/ContactSelection'),
);

const CodeInput = lazy(
  async () => await import('@/pages/AccountAssociation/CodeInput'),
);

const Confirm = lazy(
  async () => await import('@/pages/AccountAssociation/Confirm'),
);

export default [
  {
    path: 'account-association/landing-page',
    element: (
      <ComponentContainer>
        <LandingPage />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/phone',
    element: (
      <ComponentContainer>
        <HolderInput
          type={AssociationType.Phone}
          actions={[Action.AssociationsPhoneIdentification]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/email',
    element: (
      <ComponentContainer>
        <HolderInput
          type={AssociationType.Email}
          actions={[Action.AssociationsEmailIdentification]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/nif',
    element: (
      <ComponentContainer>
        <HolderInput
          type={AssociationType.Nif}
          actions={[Action.AssociationsNifIdentification]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/passport',
    element: (
      <ComponentContainer>
        <HolderInput
          type={AssociationType.Passport}
          actions={[Action.AssociationsPassportIdentification]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/contact-selection',
    element: (
      <ComponentContainer>
        <ContactSelection />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/phone-code',
    element: (
      <ComponentContainer>
        <CodeInput
          type="Phone"
          secondStep={false}
          ValidActions={[Action.AssociationsPhoneIdentificationCodeValidation]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/email-code',
    element: (
      <ComponentContainer>
        <CodeInput
          type="Email"
          secondStep={false}
          ValidActions={[Action.AssociationsEmailIdentificationCodeValidation]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/second-step-phone-code',
    element: (
      <ComponentContainer>
        <CodeInput
          type="Phone"
          secondStep
          ValidActions={[
            Action.AssociationsNifIdentificationSecondValidationPhoneCodeValidation,
            Action.AssociationsPassportIdentificationSecondValidationPhoneCodeValidation,
          ]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/second-step-email-code',
    element: (
      <ComponentContainer>
        <CodeInput
          type="Email"
          secondStep
          ValidActions={[
            Action.AssociationsNifIdentificationSecondValidationEmailCodeValidation,
            Action.AssociationsPassportIdentificationSecondValidationEmailCodeValidation,
          ]}
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'account-association/confirm',
    element: (
      <ComponentContainer>
        <Confirm
          type="Confirmation"
          ValidActions={[
            Action.AssociationsShowDetailsPrimary,
            Action.AssociationsShowDetailsSecondary,
          ]}
        />
      </ComponentContainer>
    ),
  },
];
