import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { ContainerSkinProps } from '../container.props';

export const ContainerWoo: FC<ContainerSkinProps> = ({
  children,
  className,
  isDarkMode,
  el,
  onSubmit,
}) => {
  const klass = htmlClass(
    'max-desktop-sm:overflow-x-hidden relative overflow-auto    flex flex-col flex-nowrap justify-start items-start  z-10 h-svh',
    'tablet-sm:mx-auto ',
    'tablet-st:px-0',
    'desktop-sm:w-svw',
    className ?? '',
  );

  const containerClass = htmlClass(
    'desktop-sm:flex desktop-sm:justify-center desktop-sm:items-center desktop-sm:h-[100dvh]',
  );

  switch (el) {
    case 'form':
      return (
        <>
          <div className={containerClass}>
            <BackgroundImage />
            <form
              className={klass}
              onSubmit={(ev) => {
                onSubmit?.(ev as unknown as Event);
              }}>
              {children}
            </form>
          </div>
        </>
      );

    case 'div':
    default:
      return (
        <>
          <div className={containerClass}>
            <BackgroundImage />
            <div className={klass}>{children}</div>
          </div>
        </>
      );
  }
};

function BackgroundImage() {
  return (
    <div
      className="hidden bg-clip-border desktop-sm:block w-full h-[100dvh] fixed   -right-[345px] top-0 desktop-md:-right[200px] desktop-md:h-[102dvh] desktop-st:-right-[243px] desktop-st:w-[1150px] desktop-lg:left-[30%] desktop-sm:translate-x-[20%] desktop-st:translate-x-[10%]"
      style={{
        backgroundImage: 'url(/images/woo/background.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: '100% 100%',
      }}
    />
  );
}
