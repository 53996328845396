import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { SubContainerSkinProps } from '../subcontainer.props';

export const SubContainerSporting: FC<SubContainerSkinProps> = ({
  el,
  children,
  className,
  mainClassName,
}) => {
  const kclass = htmlClass(
    `flex flex-col px-5 pt-[45px] w-full`,
    `tablet-sm:max-w-[516px]  tablet-sm:mx-auto tablet-sm:px-0 `,
    `desktop-sm:pt-10 desktop-sm:px-14 desktop-sm:mx-0 desktop-sm:max-w-full  desktop-sm:relative desktop-sm:overflow-visible`,
    mainClassName ?? '',
  );

  return (
    <div className={kclass}>
      <div className={htmlClass(className ?? '')}>{children}</div>
    </div>
  );
};
