import {
  AppleExternalLoginProvider,
  FacebookExternalLoginProvider,
  GoogleExternalLoginProvider,
  IStorage,
  NosIdAppContextType,
  useHasToApplyDarkTheme,
  useNosIdAppContext,
} from '@nosinovacao/nosid-mfe-common';
import React, { useMemo } from 'react';
import { SignInConfiguration } from '@/models';
import {
  DeviceIdService,
  EnterpriseContextService,
  UtilsService,
  NavigationStateService,
  LoginHintContextService,
} from '@/services';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppContextType {
  utilsService: UtilsService;
  deviceIdService: DeviceIdService;
  enterpriseContextService: EnterpriseContextService;
  loginHintContextService: LoginHintContextService;
  navigationStateService: NavigationStateService;
  appleLoginProvider: AppleExternalLoginProvider;
  facebookLoginProvider: FacebookExternalLoginProvider;
  googleLoginProvider: GoogleExternalLoginProvider;
}

export const AppContext = React.createContext<AppContextType>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as AppContextType,
);

type SigninContext = NosIdAppContextType &
  AppContextType & { config: SignInConfiguration; canUseDarkTheme: boolean };

export function useAppContext(): SigninContext {
  const nosidContext = useNosIdAppContext();
  const signinContext = React.useContext(AppContext);
  const canUseDarkTheme = useHasToApplyDarkTheme(
    nosidContext.templates.clientTemplate,
  );
  return useMemo(() => {
    return {
      ...nosidContext,
      ...signinContext,
      config: nosidContext.config as SignInConfiguration,
      canUseDarkTheme,
    };
  }, [nosidContext, signinContext, canUseDarkTheme]);
}

export function createApplicationContext(
  env: SignInConfiguration,
  localStorage: IStorage,
  sessionStorage: IStorage,
  nosidContext: NosIdAppContextType,
): AppContextType {
  return {
    utilsService: new UtilsService(),
    deviceIdService: new DeviceIdService(localStorage),
    enterpriseContextService: new EnterpriseContextService(sessionStorage),
    loginHintContextService: new LoginHintContextService(
      sessionStorage,
      nosidContext,
    ),
    navigationStateService: new NavigationStateService(sessionStorage),
    appleLoginProvider: new AppleExternalLoginProvider(
      env.ExternalLoginProviders,
    ),
    facebookLoginProvider: new FacebookExternalLoginProvider(
      env.ExternalLoginProviders,
    ),
    googleLoginProvider: new GoogleExternalLoginProvider(
      env.ExternalLoginProviders,
    ),
  };
}
