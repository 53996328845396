import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { ContainerSkinProps } from '../container.props';

export const ContainerDefault: FC<ContainerSkinProps> = ({
  children,
  className,
  isDarkMode,
  el,
  onSubmit,
}) => {
  const klass = htmlClass(
    'max-desktop-sm:overflow-x-hidden relative overflow-scroll pt-6  flex flex-col flex-nowrap justify-start items-start  z-10 max-desktop-sm:h-svh',
    'tablet-sm:pt-[120px] tablet-sm:mx-auto ',
    'tablet-st:px-0',
    'desktop-sm:w-svw desktop-sm:shadow-level4 desktop-sm:rounded-lg desktop-sm:items-center  desktop-sm:mx-0 desktop-sm:max-w-[636px] desktop-sm:pt-0 desktop-sm:overflow-hidden',
    className ?? '',
    isDarkMode
      ? 'bg-grey-800 max-desktop-sm:bg-grey-900 desktop-sm:p-[1px]'
      : 'desktop-sm:bg-white desktop-sm:border-grey-100 desktop-sm:border-[1px]',
  );

  const containerClass = htmlClass(
    ' desktop-sm:flex desktop-sm:justify-center desktop-sm:items-center desktop-sm:h-[100dvh]',
    isDarkMode ? 'bg-grey-900' : 'desktop-sm:bg-grey-75',
  );

  switch (el) {
    case 'form':
      return (
        <div className={containerClass}>
          <BackgroundImage isDarkMode={isDarkMode} />
          <form
            className={klass}
            onSubmit={(ev) => {
              onSubmit?.(ev as unknown as Event);
            }}>
            {children}
          </form>
        </div>
      );

    case 'div':
    default:
      return (
        <div className={containerClass}>
          <BackgroundImage isDarkMode={isDarkMode} />
          <div className={klass}>{children}</div>
        </div>
      );
  }
};

function BackgroundImage({ isDarkMode }: { isDarkMode: boolean }) {
  return (
    <div
      className="hidden desktop-sm:block w-[60%] h-[100dvh] fixed   -left-[345px] top-0 desktop-md:-left[200px] desktop-md:h-[102dvh] desktop-st:-left-[243px] desktop-st:w-[1150px] desktop-lg:left-[30%] desktop-lg:-translate-x-[100%]"
      style={{
        backgroundImage: isDarkMode
          ? 'url(/images/nos/background-dark.svg)'
          : 'url(/images/nos/background.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '100% 90%',
      }}
    />
  );
}
