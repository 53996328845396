import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { HeaderProps } from '../header.props';
import { BackButton } from '@/components/backButton';
import { IconLogo } from '@/components/IconLogo';
import { useShowLogo } from '@/hooks/showLogo.hook';

export const HeaderSporting: FC<HeaderProps> = ({
  children,
  className,
  displayLogo,
  displayReturnButton,
}) => {
  const showLogo = useShowLogo(displayLogo);

  return (
    <div className="flex flex-col">
      {showLogo && (
        <>
          <div className="w-full flex desktop-sm:justify-center">
            <IconLogo />
          </div>
          <div
            className={htmlClass(
              'flex flex-col mt-10 tablet-sm:mt-8 desktop-sm:mt-4',
              className ?? '',
            )}>
            {displayReturnButton?.display && (
              <BackButton {...displayReturnButton} />
            )}
          </div>
        </>
      )}
      {children}
    </div>
  );
};
