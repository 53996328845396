import { lazy } from 'react';
import { Action } from '@/models';
import { ComponentContainer } from '@/components/componentContainer';

const LandginPage = lazy(
  async () => await import('@/pages/PasswordRecovery/LandingPage'),
);

const CodeInput = lazy(
  async () => await import('@/pages/PasswordRecovery/CodeInput'),
);

const ContactSelection = lazy(
  async () => await import('@/pages/PasswordRecovery/ContactSelection'),
);

const NewPassword = lazy(
  async () => await import('@/pages/PasswordRecovery/NewPassword'),
);

const Complete = lazy(
  async () => await import('@/pages/PasswordRecovery/Complete'),
);

const FromEmail = lazy(
  async () => await import('@/pages/PasswordRecovery/FromEmail'),
);

const Pending = lazy(
  async () => await import('@/pages/PasswordRecovery/Pending'),
);

const NewPasswordFromEmail = lazy(
  async () => await import('@/pages/PasswordRecovery/NewPasswordFromEmail'),
);

export default [
  {
    path: 'password-recovery/landing-page',
    element: (
      <ComponentContainer>
        <LandginPage action={Action.PasswordRecovery} />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/mandatory',
    element: (
      <ComponentContainer>
        <LandginPage action={Action.PasswordRecoveryMandatory} />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/email-sent',
    element: (
      <ComponentContainer>
        <CodeInput
          ValidActions={[Action.PasswordRecoveryEmailCodeValidation]}
          type="Email"
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/sms-sent',
    element: (
      <ComponentContainer>
        <CodeInput
          ValidActions={[Action.PasswordRecoveryPhoneCodeValidation]}
          type="Phone"
        />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/contact-selection',
    element: (
      <ComponentContainer>
        <ContactSelection />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/new-password',
    element: (
      <ComponentContainer>
        <NewPassword />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/complete',
    element: (
      <ComponentContainer>
        <Complete />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/from-email',
    element: (
      <ComponentContainer>
        <FromEmail />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/pending-status',
    element: (
      <ComponentContainer>
        <Pending />
      </ComponentContainer>
    ),
  },
  {
    path: 'password-recovery/new-password-from-email',
    element: (
      <ComponentContainer>
        <NewPasswordFromEmail />
      </ComponentContainer>
    ),
  },
];
