import {
  ErrorCodes,
  useBaseNosidStoreState,
  useI18n,
} from '@nosinovacao/nosid-mfe-common';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AUTHORIZE_STORAGE_KEY,
  LOGIN_HINT_STORAGE_KEY,
  CLIENT_ID_STORAGE_KEY,
} from '@/constants';
import { useAppContext } from '@/context';
import {
  Action,
  ContactRollbackSuccess,
  NavigationMap,
  ResponseAction,
} from '@/models';
import { NavigationContext, NavigationContextType } from './state';

export const NavigationProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { show } = useBaseNosidStoreState().toast;

  const { t } = useI18n();
  const {
    navigationStateService,
    sessionStorageService,
    utilsService,
    templates,
  } = useAppContext();

  const [noop, refreshState] = useState(false);

  const refresh = () => {
    refreshState((p) => !p);
  };

  const data = useMemo<ResponseAction | null>(
    () => navigationStateService.getCurrentState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, noop],
  );

  const previousPageData = useMemo<ResponseAction | null>(
    () => navigationStateService.getPreviousUrlData() ?? null,
    [navigationStateService],
  );

  const nextPageFunc = useCallback(
    (avoidSameModule = false) => {
      return navigationStateService.getNextUrlData(avoidSameModule) ?? null;
    },
    [navigationStateService],
  );

  const handleSnackbarError = useCallback(
    (res: ResponseAction) => {
      const errorIdKey = `Errors.${res.Error?.Code}.Id`;
      const errorMessageKey = `Errors.${res.Error?.Code}.Message`;
      const errorId = t(errorIdKey);
      const errorMessage = t(errorMessageKey);

      show({
        title: errorId !== errorIdKey ? errorId : t('Errors.DefaultError.Id'),
        type: 'error',
        subtitle:
          errorMessage !== errorMessageKey
            ? errorMessage
            : t('Errors.DefaultError.Message').toString(),
      });
    },
    [show, t],
  );

  const navigateTo = useCallback(
    (res: ResponseAction<unknown>, queryParams?: string) => {
      res.PreviousAction = navigationStateService.getCurrentState()?.Action;

      if (res.Action === Action.RedirectTo) {
        const clientId = sessionStorage.getItem(CLIENT_ID_STORAGE_KEY);
        const loginHint = sessionStorage.getItem(LOGIN_HINT_STORAGE_KEY);
        sessionStorageService.remove(AUTHORIZE_STORAGE_KEY);
        sessionStorageService.remove(LOGIN_HINT_STORAGE_KEY);
        utilsService.redirect(
          `${res.Data as string}${clientId && `&client_id=${clientId}`}${
            loginHint && `&login_hint=${loginHint}`
          }`,
        );
        return;
      }

      //	ERRORS WITHIN SAME VIEW MUST CHECK THE NAVIGATION MAP FIRST
      const path = !res.Error
        ? NavigationMap.getRoutePath(res.Action, undefined, templates)
        : NavigationMap.getRoutePath(res.Action, res?.Error?.Code, templates) ??
          '/generic/error';

      if (!path) {
        console.error('Could not link CODE_ACTION to navigate to view', res);
        return;
      }

      //	NEED TO ADD / TO THE PATH TO MATCH EVENT URL
      const hash = window.btoa(JSON.stringify({ path, date: Date.now() }));

      const search = queryParams ?? '';

      const url = `${path}${search}#${hash}`;
      navigationStateService.saveNavigation(url, res);
      navigate(url);
    },
    [
      navigate,
      navigationStateService,
      sessionStorageService,
      templates,
      utilsService,
    ],
  );

  const updateCurrentDataState = useCallback(
    (Data?: any, clearErrors = true) => {
      const st = navigationStateService.getCurrentState()!;
      navigationStateService.updateCurrentState({
        ...st,
        Data: {
          ...st.Data,
          ...Data,
        },
        Error: clearErrors ? undefined : st.Error,
      });
      refresh();
    },
    [navigationStateService],
  );
  const navigateToError = useCallback(
    (res: ResponseAction<unknown>) => {
      //	ERRORS CAN ONLY BE WITH INVALID REQUEST CODE ACTION OR WITH SAME CODE ACTION AS CURRENT COMPONENT VIEW
      //	ALL INVALID REQUESTS ARE REDIRECTED TO GENERIC ERROR VIEW
      //	ERRORS WITH SAME CODE ACTION AS CURRENT VIEW SHOULD BE HANDLED IN CURRENT VIEW
      //	IS INTERNAL ERROR MUST ALWAYS THROW A SNACKBAR. OTHERWISE MIHAIL WILL KICK ME
      //	CANNOT SEND SECURITY CODE THROW A SNACKBAR AS WELL
      // IF ERROR CODE IS CHALLENGE, UPDATE VIEW DATA WITH ERROR AND RETURN
      if (res.Error?.Code === ErrorCodes.Challenge) {
        const st = navigationStateService.getCurrentState()!;

        navigationStateService.updateCurrentState({
          ...st,
          Error: {
            Code: res.Error.Code,
            ErrorCode: res.Error.ErrorCode,
          },
        });
        refresh();
        return;
      }
      //	HANDLE INTERNAL ERRORS AND CANNOT SEND SECURITY CODE
      if (res.Error?.Code === ErrorCodes.NonLegitimateUser) {
        handleSnackbarError(res);
        const st = navigationStateService.getCurrentState()!;

        navigationStateService.updateCurrentState({
          ...st,
          Error: {
            Code: ErrorCodes.Challenge,
            ErrorCode: ErrorCodes.Challenge,
          },
        });
        refresh();
        return;
      }
      if (res.Error?.Code === ErrorCodes.ContactsRollbackIsNotValidAnymore) {
        navigateTo({
          ...res,
          Action: Action.RollbackUserContacts,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          Data: {
            TwoFactorEnable: false,
            alreadyRollback: true,
          } as ContactRollbackSuccess,
        });

        return;
      }

      //	REDIRECT TO GENERIC ERROR VIEW
      if (
        res.Action === Action.InvalidRequest ||
        res.Action === Action.InvalidTokenError ||
        res.Action === Action.ForbidenTokenError
      ) {
        navigateTo(res);
        return;
      }

      //	HANDLE INTERNAL ERRORS AND CANNOT SEND SECURITY CODE
      if (
        !!res.Error?.IsInternalError ||
        res.Error?.Code === ErrorCodes.CannotSendSecurityCode ||
        res.Error?.Code === ErrorCodes.NotFoundSecurityCode
      ) {
        handleSnackbarError(res);
        return;
      }

      if (navigationStateService.getCurrentState()?.Action === res.Action) {
        //	SHOW ERROR IN SAME VIEW
        res = {
          ...res,
          Token: navigationStateService.getCurrentState()?.Token ?? '',
        };

        navigationStateService.updateCurrentState(res);
        refresh();
        return;
      }

      //	HANDLE ERRORS WITH SPECIFIC CODE ACTIONS LIKE USERBLOCKED... THEY ARE ERRORS WITH CODE ACTIONS SPECIFIC
      navigateTo(res);
    },
    [handleSnackbarError, navigateTo, navigationStateService],
  );
  const value = useMemo<NavigationContextType>(() => {
    return {
      data,
      error: data?.Error,
      navigateTo,
      navigateToError,
      updateCurrentDataState,
      previousPageData,
      nextPageFunc,
    };
  }, [
    data,
    navigateTo,
    navigateToError,
    updateCurrentDataState,
    previousPageData,
    nextPageFunc,
  ]);
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};
