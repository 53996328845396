import { FC } from 'react';
import { useAppContext } from '@/context';
import { HeaderProps } from './header.props';
import { HeaderWoo } from './woo/header.woo';
import { HeaderDefault } from './default/header.default';
import { HeaderSporting } from './sporting/header.sporting';
import { HeaderBenfica } from './benfica/header.benfica';
import { HeaderWtf } from './wtf/header.wtf';

export const Header: FC<HeaderProps> = ({ children, ...props }) => {
  const { templates } = useAppContext();

  switch (true) {
    case templates.isWtfTemplate:
      return <HeaderWtf {...props}>{children}</HeaderWtf>;
    case templates.isWooTemplate:
      return <HeaderWoo {...props}>{children}</HeaderWoo>;
    case templates.isBenficaTemplate:
      return <HeaderBenfica {...props}>{children}</HeaderBenfica>;
    case templates.isSportingTemplate:
      return <HeaderSporting {...props}>{children}</HeaderSporting>;
    default:
      return <HeaderDefault {...props}>{children}</HeaderDefault>;
  }
};
