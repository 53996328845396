import { IconLoader, Toast } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { NavigationProvider } from '@/navigation';

export const MainLayout: FC = () => {
  return (
    <NavigationProvider>
      <Toast>
        <IconLoader mainLoader forceVisible={false}>
          <Outlet />
        </IconLoader>
      </Toast>
    </NavigationProvider>
  );
};
